function convertDate (date: string) {
  return new Date(date).toLocaleDateString("en-us", {
    hour12: false,
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export default convertDate;
