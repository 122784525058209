import axios from "axios";
class PreviewService {
  async getPreviewContent (material) {
    return await axios
      .post("api/print", {
        CartItems: [material],
        PrintCopies: 1,
        ForPreview: true,
        PublishToPortal: false,
        DistributionAction: 3,
      })
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async getPreviewVideoContent (material) {
    return await axios
      .post("api/preview/video", {
        ...material,
        DistributionAction: 3,
      })
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new PreviewService();
