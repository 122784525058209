import axios from "axios";

class HistoryService {
  async getActivityHistory () {
    return await axios
      .get("api/activityHistory")
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async getPublishedContentItem (s3ObjectKey: string) {
    return await axios
      .get(`api/activityHistory/${s3ObjectKey}`)
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new HistoryService();
